<template>
  <div v-if="initiatorId">
    <FollowUpTriggersList :initiatorId="initiatorId" :isEditable="isEditable" :trigger="selectedTrigger"/>
    <div class="p-mt-2">
      <Button v-if="isEditable && getTrigger.initiatorId && getMode"
              v-tooltip.bottom="_t('Save')"
              :loading="loading"
              class="p-button-success p-button-text p-button-rounded"
              icon="pi pi-save" style="float: right; margin-top: 5px; z-index: 9"
              @click="onSaveTrigger"
      />
      <Button v-if="isEditable && getTrigger.initiatorId && getMode"
              v-tooltip.bottom="_t('Cancel')"
              :loading="loading"
              class="p-button-danger p-button-text p-button-rounded"
              icon="pi pi-times" style="float: right; margin-top: 5px; z-index: 9"
              @click="onCancelTrigger"
      />
      <div style="clear: both"></div>
    </div>
    <div v-if="noFollowupWarningFlag" class="p-mt-2">
      <span style="float: right; margin-top: 0px; z-index: 9; color: red;">
        {{ _t('noFollowupWarning') }}
      </span>
      <div style="clear: both"></div>
    </div>
    <TabView v-if="getTrigger.initiatorId && getMode" v-model:activeIndex="activeTab">
      <TabPanel :header="_t('menu.general')">
        <FollowUpBasicsTab :isEditable="isEditable"/>
      </TabPanel>
      <TabPanel :header="_t('menu.participants')">
        <FollowUpParticipantsTab ref="followUpParticipants" :isEditable="isEditable"/>
      </TabPanel>
      <TabPanel :header="_t('label_advanced')">
        <FollowUpAdvancedTab :isEditable="isEditable"/>
      </TabPanel>
      <TabPanel :header="_t('label_Icon')">
        <FollowUpIconTab :isEditable="isEditable"/>
      </TabPanel>
      <TabPanel :header="_t('label_Schedules')">
        <FollowUpSchedulesTab :isEditable="isEditable" :orgId="orgId"/>
      </TabPanel>
    </TabView>
  </div>
  <Toast/>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import FollowUpTriggersList from './triggers-list';
import FollowUpBasicsTab from './tabs/basics-tab';
import FollowUpParticipantsTab from './tabs/participants-tab';
import FollowUpAdvancedTab from './tabs/advanced-tab';
import FollowUpIconTab from './tabs/icon-tab';
import FollowUpSchedulesTab from "./tabs/schedules-tab";

export default {
  name: 'follow-up-page',
  components: {
    FollowUpTriggersList,
    FollowUpBasicsTab,
    FollowUpParticipantsTab,
    FollowUpAdvancedTab,
    FollowUpIconTab,
    FollowUpSchedulesTab,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: () => false,
    },
    initiatorId: {
      type: [Number, String, null],
      default: () => null,
    },
    orgId: {
      type: [Number, String, null],
      default: () => null,
    },
  },
  data: () => ({
    activeTab: 0,
    loading: false,
    selectedTrigger: null,
    noFollowupWarningFlag: false
  }),
  methods: {
    ...mapActions({
      'callAvailableTriggers': 'consequentials/callAvailableTriggers',
      'callList': 'consequentials/callList',
      'callSave': 'consequentials/callSave',
    }),
    ...mapMutations({
      'setInitiatorId': 'consequentials/setInitiatorId',
      'setMode': 'consequentials/setMode',
      'setOrgId': 'consequentials/setOrgId',
      'setDefaultTrigger': 'consequentials/setDefaultTrigger',
    }),
    onSaveTrigger() {
      this.loading = true;
      this.setOrgId(this.orgId);
      let isSuccessful = this.$refs.followUpParticipants.onSave();
      if (isSuccessful === true) {
        if (this.initiatorId === '_') {
          this.setInitiatorId("")
        }
        this.noFollowupWarningFlag = false
        this.callSave()
            .then(() => {
              this.onCancelTrigger();
              this.$toast.add({severity: 'success', summary: 'Saving', detail: 'Saving was successful', life: 3000})
            })
            .catch(error => {
              let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
              console.log(data)
              this.noFollowupWarningFlag = true
            })
            .finally(() => {
              this.loading = false;
            })
      } else {
        this.loading = false;
      }
    },
    onCancelTrigger() {
      this.loading = true;
      this.setDefaultTrigger();
      this.setMode(null);
      this.setInitiatorId(this.initiatorId);
      this.callAvailableTriggers();
      this.callList();
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      'getTrigger': 'consequentials/getTrigger',
      'getMode': 'consequentials/getMode',
    }),
  },
  watch: {
    initiatorId: function () {
      this.setMode(null);
      this.setInitiatorId(this.initiatorId);
      this.setOrgId(this.orgId);
      if (this.initiatorId && this.initiatorId !== '_') {
        this.callAvailableTriggers();
        this.callList();
      }
    }
  }
}
</script>
